.ButtonDevice :focus ~ button.bg-blue-400 {
    background-color: rgb(158, 22, 22);
}
.btnSimulation {
    display: none;
}

.btnSimulation:checked + label {
    border-color: rgba(0, 81, 231, 0.63);
}

h1 {
    font-size: 4.8rem !important;
}

.body__faq h2 {
    font-size: 2.4rem !important;
    font-weight: 600;
}

.body__faq h3 {
    font-weight: 600;
    margin: top 20px;
}

.body__faq p {
    margin: top 5px;
}

.body__faq ul li {
    list-style: circle;
    margin-left:15px;
}
