.LineSeperator {
  margin-top: 2%;
  border-bottom: 1px solid grey;
  border-radius: 1px;
}

.Header {
  margin-left: 20px;
  margin-right: 20px;
}
