.datepicker__startDate {
  padding: 1rem;
  outline: 1px solid black;
}

.datepicker__endDate {
  padding: 1rem;
  outline: 1px solid black;
}

.datepicker:hover {
  cursor: pointer;
}

.react-datepicker__time-container--with-today-button {
  left: 240px;
}

.datepicker:focus {
  cursor: text;
}

.mt-10 {
  margin-top: 6.5rem;
}