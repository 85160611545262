.ButtonDevice :focus ~ button.bg-blue-400 {
    background-color: rgb(158, 22, 22);
}
.btnSimulation {
    display: none;
}

.btnSimulation:checked + label {
border-color: rgba(0, 81, 231, 0.63);
}
