.language_select {
  font-size: 14px;
}

.language_select button {
  color: blue;
}

.language_select button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.isSelected {
  color: black !important;
  text-decoration: none;
}

.isSelected:hover {
  color: black !important;
  text-decoration: none !important;
  cursor: text !important;
}
