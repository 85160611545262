table {
  text-align: left;
  margin-bottom: 4rem;
  overflow-x: auto;
}

th {
  padding: 0.4rem;
  font-size: 14px;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}

td {
  font-size: 12px;
  padding: 0.4rem;
  padding-top: 1.0rem;
  padding-bottom: 1.0rem;
}

tbody {
  border-bottom: 0.2px solid black;
}

tbody:hover {
  background-color: rgb(207, 207, 207);
}


tbody:hover > tr > td > select {
  background-color: rgb(207, 207, 207);
}

.admin-panel_navigation {
  margin-top: 1rem;
  border: 0.2px solid black;
}

.navigation_tab {
  padding: 1rem;
  color: black;
  width: 200px;
}

.navigation_tab:hover {
  background-color: rgb(207, 207, 207);
  cursor: pointer;
}

.isSelectedTab {
  background-color: rgb(207, 207, 207);
  font-weight: bold;
}

.text-right {
  text-align: right;
}
